import Navbar from "./components/Navbar";
import { useDispatch, useSelector } from "react-redux";

import Bags from "./components/Bags/Bags";
import {
  selectLoading,
  changeLoadingState,
  setCard,
  selectCard,
} from "./features/cardSlice";
import { useEffect } from "react";

const url = "https://course-api.com/react-useReducer-cart-project";

function App() {
  const loading = useSelector(selectLoading);
  const card = useSelector(selectCard);
  const dispatch = useDispatch();

  const setNotLoading = () => {
    dispatch(changeLoadingState(false));
  };

  const fetchCardData = () => {
    const data = [
      {
        id: "rec1JZlfCIBOPdcT2",
        title: "Samsung Galaxy S8",
        price: "399.99",
        img: "https://dl.airtable.com/.attachments/64b266ad865098befbda3c3577a773c9/24497852/yedjpkwxljtb75t3tezl.png",
        amount: 1,
      },
      {
        id: "recB6qcHPxb62YJ75",
        title: "google pixel",
        price: "499.99",
        img: "https://dl.airtable.com/.attachments/91c88ae8c1580e2b762ecb3f73ed1eed/a633139a/phone-1_gvesln.png",
        amount: 1,
      },
      {
        id: "recdRxBsE14Rr2VuJ",
        title: "Xiaomi Redmi Note 2",
        price: "699.99",
        img: "https://dl.airtable.com/.attachments/bae9208dc34f35128749ecda5b999e84/337c285d/phone-3_h2s6fo.png",
        amount: 1,
      },
      {
        id: "recwTo160XST3PIoW",
        title: "Samsung Galaxy S7",
        price: "599.99 ",
        img: "https://dl.airtable.com/.attachments/91ee456448cef47deec553a2ea3fa8ad/b08bec68/phone-2_ohtt5s.png",
        amount: 1,
      },
    ];
    dispatch(setCard(data));
  };

  useEffect(() => {
    fetchCardData();
    setNotLoading();
  }, []);

  
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen font-poppins">
        <h1 className="text-blue-900 text-center font-semibold uppercase text-5xl">
          loading ...
        </h1>
      </div>
    );
  } else {
    return (
      <div className="App font-poppins">
        <Navbar />
        <h1 className="text-blue-900 text-center font-semibold uppercase text-5xl mt-20 mb-10">
          your Bags
        </h1>
        <Bags />
      </div>
    );
  }
}

export default App;
